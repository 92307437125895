import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function StepInitial({ step, handleClick }) {

  return (
    <section id="step-0" className="step step--1-col step--0">
    <div className="container">

      <div className="row">

        <div className="col-md-12">

          <div className="step__headline">
            <h1>How to register worker voters</h1>
          </div>

        </div>

      </div>

      <div className="background-wrapper">
        <StaticImage 
          src="../../../static/media/step-0-bg.png"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>

      <div className="row">

        <div className="col-md-10 offset-md-1">

          <div className="content-wrapper">

            <div className="step__content">

              <h3 className="text-center">If you or your organisation own or rent a physical workplace in the City of London with a freehold or lease, then you are eligible to register voters</h3>                            
              <p>This guide will help you easily navigate the process of registering your working voters.</p>
              <p>All kinds of organisations are able to participate as long as they own or rent a physical space in the City. This includes retail units, bars and restaurants, limited companies, limited liability partnerships, charities, trade associations, livery companies, churches and other religious bodies, banks and hospital trusts.</p>
              <p>Sole traders and partners can also register but the process is a bit different.</p>
              <p>What kind of organisation do you represent?</p>
            </div>

             <div className="step__ctas">
                <Link className="link--default" to="/voting-workers"><b>I am a sole trader or partner</b></Link>
                <button onClick={ handleClick }>I represent a limited company, LLP or other organisation ›</button>
              </div>

          </div>

        </div>

      </div>

    </div>
  </section>
  )
}
